import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Parallax, Background } from 'react-parallax';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ParallaxBanner = () => {
  const { t } = useTranslation()
  return (
    <section className='border-b-12 lg:border-b-36 border-secondary md:border-primary relative'>
      <Parallax strength={300}>
        <Background className="custom-bg">
          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <StaticImage
              src="../../../images/blog-bg2.jpg"
              layout='fullWidth'
              transformOptions='fit'
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt={t('blogSection.title')}
              style={{
                height: `120%`,
              }}
            />
          </div>
      </Background>
      <div className='px-4 w-full max-w-screen-xl mx-auto py-20 lg:py-48 relative z-10'>
        <div className='md:border-l-4 md:border-secondary md:pl-6'>
          <h3 className='md:text-2xl max-w-xl'>
            {t('blogSection.title')}
          </h3>
          <div className='mt-6'>
            <a href='https://www.kominynalata.pl' target='_blank' className='button button--secondary'>{t('blogSection.button')}</a>
          </div>
        </div>
      </div>
      </Parallax>
    </section>
  )
}

export default ParallaxBanner
