import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from '../components/organisms/HomeHero'
import HomePDGroup from '../components/organisms/HomePDGroup'
import ContactSection from "../components/organisms/ContactSection"
import CompanySection from "../components/organisms/CompanySection"
import ProductsSection from "../components/organisms/ProductsSection"
import ParallaxBanner from "../components/organisms/ParallaxBanner"
import ExpirienceSection from "../components/organisms/ExpirienceSection"
import StrategySection from "../components/organisms/StrategySection"
import Banner from "../components/organisms/Banner"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';

const IndexPage = (props) => {
  const { t } = useTranslation()
  return (
    <Layout>
    <Seo title={t('headerHeadline')} bodyClassName='homepage' />
    <HomeHero />
    <CompanySection />
    <ProductsSection />
    <ParallaxBanner />
    <ExpirienceSection />
    <StrategySection />
    <Banner
        image={
          <StaticImage
            src="../images/banner-bg.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            className='banner-bg'
            alt={t('headerHeadline')}
            style={{
              height: '100%',
            }}
        />
        }
        text={t('homeBannerSection.header')}
    />
    <HomePDGroup />
    <ContactSection />
  </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;