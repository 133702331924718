import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const ProductsSection = () => {
  const { t } = useTranslation()
  return (
    <section id='productsSection' className='px-4 pt-16 md:pb-16 border-b-12 lg:border-b-36 border-primary md:border-secondary bg-primary relative shadow-section overflow-hidden'>
      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-secondary text-lg uppercase font-bold has-decorator mb-10'>{t('productsSection.title')}</h2>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2 px-4 relative lg:static'>
            <div className='absolute top-0 left-0 w-full h-full lg:hidden products-mobile-bg'>
              <StaticImage
                src="../../../images/products-bg.jpg"
                layout='fullWidth'
                transformOptions='fit'
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt={t('productsSection.header')}
                style={{
                  height: `100%`,
                }}
              />
            </div>
            <div className='pl-6 xl:pl-0 md:pr-20 relative z-10'>
              <h3
                className='font-semibold text-secondary text-lg md:text-3xl'
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                {t('productsSection.header')}
              </h3>
              <div
                className='mt-8 lg:mt-16 mb-16 lg:mb-0 font-light md:text-lg text-primary-light'
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                <p>{t('productsSection.lead')}</p>
              </div>
              <div className='mt-4 md:mt-24 hidden lg:flex items-center '>
                <div className='mr-6 overflow-hidden' data-sal='slide-left' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                  <StaticImage
                    src="../../../images/products-pd-group.png"
                    width={135}
                    quality={100}
                    placeholder=''
                    alt={t('productsSection.pdRefractoriesLogoAlt')}
                  />
                </div>
                <div data-sal='slide-left' data-sal-delay='600' data-sal-duration='400' data-sal-easing='ease-in-out'>
                  <StaticImage
                    src="../../../images/products-pd-refractories.png"
                    width={152}
                    quality={95}
                    placeholder=''
                    alt={t('productsSection.pdRefractoriesLogoAlt')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/2 px-10 md:px-4 relative lg:static'>
            <div className='absolute bottom-0 right-0 w-full lg:w-1/2 h-full'>
              <StaticImage
                src="../../../images/home-hero-left-desktop.jpg"
                layout='fullWidth'
                transformOptions='fit'
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt={t('productsSection.header')}
                style={{
                  height: `100%`,
                }}
              />
            </div>
            <div className='flex flex-wrap -mx-2 md:-mx-6 transform lg:-translate-x-12 -translate-y-8 lg:-translate-y-12'>
              <div className='w-1/2 px-2 md:px-6 relative z-10'>
                <Link to='/produkty' className='block group'>
                  <div className='bg-secondary  pt-3 xl:pt-6 pb-12' data-sal='slide-left' data-sal-delay='300' data-sal-duration='800' data-sal-easing='ease-in-out'>
                    <div className='overflow-hidden mx-2 md:mx-3 xl:mx-6'>
                      <StaticImage
                        src="../../../images/products-1.jpg"
                        layout='fullWidth'
                        transformOptions='fit'
                        quality={100}
                        alt={t('productsSection.paroductsCatalogueTitle')}
                        className='transition-transform ease-in-out duration-500 transform group-hover:scale-125'
                      />
                    </div>

                    <h3 className='mt-8 font-bold text-xs sm:text-sm md:text-base xl:text-xl text-primary uppercase text-center leading-tight md:px-2'>
                      {t('productsSection.paroductsCatalogueTitle')}
                      <br />
                      {t('productsSection.paroductsCatalogueTitle2')}
                    </h3>
                  </div>
                </Link>
              </div>
              <div className='w-1/2 px-2 md:px-6 relative z-10'>
                <Link to='/dokumentacja' className='block group'>
                  <div className='bg-primary  pt-3 xl:pt-6 pb-12' data-sal='slide-left' data-sal-delay='500' data-sal-duration='800' data-sal-easing='ease-in-out'>
                    <div className='overflow-hidden mx-2 md:mx-3 xl:mx-6'>
                      <StaticImage
                        src="../../../images/products-2.jpg"
                        layout='fullWidth'
                        transformOptions='fit'
                        quality={100}
                        alt={t('productsSection.documentationTitle')}
                        className='transition-transform ease-in-out duration-500 transform group-hover:scale-125'
                      />
                    </div>
                    <h3 className='mt-8 font-bold text-xs sm:text-sm md:text-base xl:text-xl text-secondary uppercase text-center leading-tight md:px-2'>
                      {t('productsSection.documentationTitle')}
                      <br />
                      {t('productsSection.documentationTitle2')}
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsSection
