import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import OrdersIcon from '../../../images/svg/orders.svg'
import LogisticIcon from '../../../images/svg/logistic.svg'
import CertificateIcon from '../../../images/svg/certificate.svg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const CompanySection = () => {
  const { t } = useTranslation()
  return (
    <section id='companySection' className='px-4 py-16 border-b-12 lg:border-b-36 border-secondary md:border-secondary relative'>
      <div className='absolute bottom-0 right-0 w-full h-full bg-image'>
        <StaticImage
          layout='fullWidth'
          src="../../../images/company-bg.jpg"
          quality={95}
          alt=''
          style={{ height: `100%` }}
        />
      </div>
      <div className='w-full max-w-screen-xl mx-auto relative z-10'>
        <h2 className='text-primary text-lg uppercase font-bold has-decorator mb-10'>{t('companySection.sectionTitle')}</h2>
        <div className='flex flex-wrap md:flex-row-reverse -mx-4'>
          <div className='w-full md:w-1/2 px-4'>
            <div className='pl-6 md:pl-0'>
              <h3 className='font-semibold text-primary text-lg md:text-xl xl:text-3xl' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                {t('companySection.header')}
              </h3>
              <div className='mt-12 md:text-lg' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <p>{t('companySection.lead')}</p>
              </div>
              <div className='hidden md:block mt-4 md:mt-10' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <Link to='/firma' className='button button--secondary'>{t('moreButton')}</Link>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-4'>
            <div className='pl-6 xl:pl-20 mt-10 md:mt-0'>
              <div className='flex md:mr-8 relative' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <div className='company-icon-arrow'></div>
                <OrdersIcon width='72px' height='72px' className='flex-shrink-0 fill-current company-icon' />
                <div className='px-4'>
                  <h4 className='font-bold md:text-lg uppercase text-gray mt-2 md:mt-6 mb-4 md:mb-6'>{t('companySection.steps.step_01.title')}</h4>
                  <p>{t('companySection.steps.step_01.text')}</p>
                </div>
              </div>
              <div className='flex md:mr-8 relative' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='company-icon-arrow'></div>
                <LogisticIcon width='72px' height='72px' className='flex-shrink-0 fill-current company-icon' />
                <div className='px-4'>
                  <h4 className='font-bold md:text-lg uppercase text-gray mt-2 md:mt-6 mb-4 md:mb-6'>{t('companySection.steps.step_02.title')}</h4>
                  <p>{t('companySection.steps.step_02.text')}</p>
                </div>
              </div>
              <div className='flex md:mr-8 relative' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <CertificateIcon width='72px' height='72px' className='flex-shrink-0 fill-current company-icon' />
                <div className='px-4'>
                  <h4 className='font-bold md:text-lg uppercase text-gray mt-2 md:mt-6 mb-4 md:mb-6'>{t('companySection.steps.step_03.title')}</h4>
                  <p>{t('companySection.steps.step_03.text')}</p>
                </div>
              </div>
              <div className='block md:hidden mt-4 md:mt-10' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <Link to='/firma' className='button button--secondary'>{t('moreButton')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanySection
