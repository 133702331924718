import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Map from '../../../images/svg/pd-map.svg'

const HomePDGroup = () => {
  const { t } = useTranslation()
  return (
    <section id='pdGroupSection' className='px-4 border-b-12 lg:border-b-36border-primary py-12 md:py-24 overflow-hidden relative'>
      <div className='absolute h-full w-full top-0 left-0 overflow-hidden map-box' data-sal='fade' data-sal-delay='100' data-sal-duration='300' data-sal-easing='ease-in-out'>
        <Map className='w-auto xl:w-full h-full absolute left-1/2 xl:left-0 xl:right-0 xl:mr-0 transform -translate-x-1/2 xl:translate-x-0' />
      </div>

      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-primary text-sm md:text-lg uppercase font-bold has-decorator mb-10'>
          {t('pdGroupSection.title')}
        </h2>
        <div className='flex flex-wrap -mx-4 relative z-10'>
          <div className='w-full md:w-3/4 lg:w-1/2 px-4'>
            <h3
              className='font-semibold md:font-medium text-primary text-sm md:text-xl lg:text-3xl pl-6 md:pl-0 -mt-6 md:mt-0 w-8/12 md:w-auto'
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
            >
              {t('pdGroupSection.header')}
            </h3>
            <div
              className='hidden xl:block mt-8 lg:mt-12 mb-16 lg:mb-0 font-light md:text-lg'
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
            >
              <p>{t('pdGroupSection.lead')}</p>
            </div>
            <div className='hidden xl:block mt-4 md:mt-10' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <Link to='/pd-refractories' className='button button--primary'>{t('pdGroupSection.button')}</Link>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap items-center -mx-4 xl:-mx-12 mt-75vw xl:mt-12'>
          <div className='w-1/2 md:w-1/4 px-4 xl:px-12'>
            <div className='relative pl-8 pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl'>{t('pdGroupSection.numbers.number01.title')}</h4>
              <p className='text-sm lg:text-xl font-light m-0'>{t('pdGroupSection.numbers.number01.text')}</p>
            </div>
          </div>
          <div className='w-1/2 md:w-1/4 px-4 xl:px-12'>
            <div className='relative pl-8 pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='500' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl'>{t('pdGroupSection.numbers.number02.title')}</h4>
              <p className='text-sm lg:text-xl font-light m-0'>{t('pdGroupSection.numbers.number02.text')}</p>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-4 xl:px-12'>
            <div className='relative pr-2 leading-tight mt-10 md:mt-0'>
              <div className='bg-primary h-full w-1 absolute left-0 hidden md:block'></div>
              <div className='flex items-center justify-between'>
                <div className='pl-4 md:pl-12 px-2' data-sal='slide-left' data-sal-delay='600' data-sal-duration='400' data-sal-easing='ease-in-out'>
                  <div className='transform -translate-y-4'>
                    <StaticImage
                      src="../../../images/ref-liner.png"
                      width={124}
                      quality={95}
                      placeholder=''
                      alt={t('pdGroupSection.pdGroupLogoAlt')}
                    />
                  </div>
                </div>
                <div className='py-2 px-2' data-sal='slide-left' data-sal-delay='700' data-sal-duration='400' data-sal-easing='ease-in-out'>
                  <StaticImage
                    src="../../../images/pd-group-dark.png"
                    width={115}
                    quality={95}
                    placeholder=''
                    alt={t('pdGroupSection.pdRefractoriesLogoAlt')}
                  />
                </div>
                <div className='py-2 px-2' data-sal='slide-left' data-sal-delay='800' data-sal-duration='400' data-sal-easing='ease-in-out'>
                  <StaticImage
                    src="../../../images/pd-refractories-dark.png"
                    width={124}
                    quality={95}
                    placeholder=''
                    alt={t('pdGroupSection.refLinerLogoAlt')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='block xl:hidden relative z-10 mt-4 md:mt-10' data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
          <Link to='/pd-refractories' className='button button--primary'>{t('pdGroupSection.button')}</Link>
        </div>
      </div>
    </section>
  )
}

export default HomePDGroup
