import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Map from '../../../images/svg/map.svg'

const ExpirienceSection = () => {
  const { t } = useTranslation()
  return (
    <section id='expirienceSection' className='px-4 py-8 xl:py-16 border-b-12 lg:border-b-36 border-secondary overflow-hidden relative'>
      <div className='absolute h-full w-full max-w-screen-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' data-sal='fade' data-sal-delay='100' data-sal-duration='300' data-sal-easing='ease-in-out'>
        <Map className=' w-full xl:h-auto absolute top-1/2 -mt-24 xl:mt-0 -mr-28 xl:mr-0 transform -translate-y-1/2 scale-125 xl:scale-100 map-svg' />
      </div>

      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-secondary text-lg uppercase font-bold has-decorator mb-10'>{t('expirienceSection.title')}</h2>
        <h3 className='font-semibold text-secondary text-sm pl-6 relative z-10 block xl:hidden w-7/12 -mt-8'>
          {t('expirienceSection.header')}
        </h3>
        <div className='flex flex-wrap justify-end -mx-4'>
          <div className='w-full xl:w-1/2 px-4 relative z-10 mt-75vw xl:mt-0'>
            <div className='mb-12 lg:pl-8'>
              <h3 className='font-medium text-secondary text-lg md:text-3xl hidden xl:block'>
                {t('expirienceSection.header')}
              </h3>
              <div className='hidden xl:block mt-8 lg:mt-12 mb-16 lg:mb-0 font-light md:text-lg'>
                <p>{t('expirienceSection.lead')}</p>
              </div>
            </div>
            <div className='lg:pl-8'>
              <div className='flex flex-wrap -mx-4 lg:-mx-16'>
                <div className='w-1/2 px-4 lg:px-12 mb-4 xl:mb-10'>
                  <div className='relative pl-4 lg:pl-8 pr-2 leading-tight'>
                    <div className='bg-secondary h-full w-1 absolute left-0 shadow-sm'></div>
                    <h4 className='text-base lg:text-2xl'>{t('expirienceSection.numbers.number_01.header')}</h4>
                    <p className='text-sm lg:text-lg font-light'>{t('expirienceSection.numbers.number_01.text')}</p>
                  </div>
                </div>
                <div className='w-1/2 px-4 lg:px-12 mb-4 xl:mb-10'>
                  <div className='relative pl-4 lg:pl-8 pr-2 leading-tight'>
                    <div className='bg-secondary h-full w-1 absolute left-0 shadow-sm'></div>
                    <h4 className='text-base lg:text-2xl'>{t('expirienceSection.numbers.number_02.header')}</h4>
                    <p className='text-sm lg:text-lg font-light'>{t('expirienceSection.numbers.number_02.text')}</p>
                  </div>
                </div>
                <div className='w-1/2 px-4 lg:px-12 mb-4 xl:mb-10'>
                  <div className='relative pl-4 lg:pl-8 pr-2 leading-tight'>
                    <div className='bg-secondary h-full w-1 absolute left-0 shadow-sm'></div>
                    <h4 className='text-base lg:text-2xl'>{t('expirienceSection.numbers.number_03.header')}</h4>
                    <p className='text-sm lg:text-lg font-light'>{t('expirienceSection.numbers.number_03.text')}</p>
                  </div>
                </div>
                <div className='w-1/2 px-4 lg:px-12 mb-4 xl:mb-10'>
                  <div className='relative pl-4 lg:pl-8 pr-2 leading-tight'>
                    <div className='bg-secondary h-full w-1 absolute left-0 shadow-sm'></div>
                    <h4 className='text-base lg:text-2xl'>{t('expirienceSection.numbers.number_04.header')}</h4>
                    <p className='text-sm lg:text-lg font-light'>{t('expirienceSection.numbers.number_04.text')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpirienceSection
