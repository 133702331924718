import React, { useEffect, useRef, useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const HomeHero = () => {
  const sliderRef = useRef('slider');
  const captionSliderRef = useRef('caption-slider');

  const [sliderIndex, setSliderIndex] = useState(0)
  // const [sliderCaptionIndex, setSliderCaptionIndex] = useState(0)

  const [mainSlider, setMainSlider] = useState(null)
  const [captionSlider, setCaptionSlider] = useState(null)

  // const navItem = (_, i) => {
  //   const isActive = i === sliderIndex
  //   return <div className={`w-4 h-4 mr-2 cursor-pointer ${isActive ? 'bg-secondary' : 'border-2 border-secondary'}`} key={i} onClick={() => sliderRef.current.slickGoTo(i)} />;
  // };

  var settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => setSliderIndex(next)
  };

  useEffect(() => {
    setMainSlider(sliderRef.current)
    setCaptionSlider(captionSliderRef.current)
  }, [sliderRef, captionSliderRef])

  const { t } = useTranslation()

  const heroItems = [
    <StaticImage layout='fullWidth' transformOptions='fit' style={{ height: `100%` }} src='../../../images/hero/slide-1.jpg' alt='' />,
  ]

  const heroCaption = [
    <div>{t('hero.slides.slideCaption01.begin')} <b className='font-bold leading-snug'>{t('hero.slides.slideCaption01.highlight')}</b> {t('hero.slides.slideCaption01.end')}</div>,
  ]

  const photos = heroItems.map((heroItem, index) => {
    return (
      <div key={index}>
        {heroItem}
      </div>
    )
  })

  const captions = heroCaption.map((caption, index) => {
    return (
      <div key={index}>
        {caption}
      </div>
    )
  })

  return (
    <section className='w-full lg:h-screen border-b-12 lg:border-b-36 border-primary shadow-section'>
      <div className='flex flex-wrap lg:flex-row-reverse h-full relative'>
        <div className='w-full h-full hidden lg:block'>
          <Slider {...settings} asNavFor={captionSlider} ref={sliderRef}>
            {photos}
          </Slider>
        </div>

        <div className='w-full relative lg:static flex items-center home-hero-container'>
          <div className='absolute bottom-0 right-0 w-full lg:w-1/2 h-full lg:hidden'>
            <StaticImage
              src="../../../images/hero/home-hero-mobile.jpg"
              layout='fullWidth'
              transformOptions='fit'
              alt=''
              style={{
                height: `100%`,
              }}
            />
          </div>
          <div className='absolute left-0 bottom-6 lg:bottom-16  w-full'>
            <div className='w-full max-w-screen-xl mx-auto relative'>
              <div className='mx-4 my-10 lg:mx-4 lg:mr-32'>
                <div className='px-6 lg:px-4 lg:pl-8 mb-40 lg:mb-8 lg:mr-36 lg:w-1/2 home-hero-logo'>
                  <StaticImage
                    src='../../../images/chanaka-hero.png'
                    placeholder='none'
                    width={420}
                    alt='Chanaka'
                  />
                </div>
                <div className='w-full lg:w-1/2 bg-primary p-4 lg:p-8' data-sal='fade' data-sal-delay='300' data-sal-easing='ease'>
                  <div className='text-secondary text-sm lg:text-lg font-semibold block lg:hidden'>
                    {heroCaption[0]}
                  </div>
                  <div className='text-secondary text-sm lg:text-lg font-semibold hidden lg:block'>
                    <Slider
                      asNavFor={mainSlider}
                      ref={captionSliderRef}
                      slidesToShow={1}
                      swipeToSlide={true}
                      focusOnSelect={true}
                      dots={false}
                      arrows={false}
                    >
                      {captions}
                    </Slider>
                  </div>
                  {/* <div className='mt-12 text-secondary hidden lg:flex'>
                    {heroItems.map(navItem)}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero
